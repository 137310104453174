import {SubscriptionPlansEnum} from '../types/types'

const SUPER_ADMIN_EMAILS = ['sarfaraz@mediusware.com', 'danny@klevere.ai']
const BETA_USER_EMAILS = ['build@thebarlabs.com', 'jpdeluna.cpa@gmail.com']
const KLEVERE_BETA_USER_EMAILS = ['beta@klevere.ai', 'beta@merepost.com','beta_customer1@klevere.ai','beta_customer2@klevere.ai','beta_customer3@klevere.ai']

const USER_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
}

export const isSuperAdmin = (email: string | undefined) => {
  return email ? SUPER_ADMIN_EMAILS.includes(email) : false
}

export const isKlevereBetaUser = (email: string | undefined) => {
  return email ? KLEVERE_BETA_USER_EMAILS.includes(email) : false
}

export const isBetaUser = (email: string | undefined) => {
  return email ? BETA_USER_EMAILS.includes(email) : false
}

export const isAdmin = (role: string | undefined) => {
  return role === USER_ROLES.ADMIN
}

export const isUser = (role: string | undefined) => {
  return role === USER_ROLES.USER
}

export const isActiveUser = (status: boolean | undefined) => {
  return status
}

export const isAppSumoUser = (user: any) => {
  return [
    SubscriptionPlansEnum.APPSUMOTIER1,
    SubscriptionPlansEnum.APPSUMOTIER2,
    SubscriptionPlansEnum.APPSUMOTIER3,
  ].includes(user?.subscription.name)
}

export const isPaidUser = (user: any) => {
  if (user?.subscription.name !== SubscriptionPlansEnum.FREE) {
    return true
  }
  return false
}

export const isFreemiumUser = (user: any) => {
  if (user?.subscription.name === SubscriptionPlansEnum.FREE && user?.subscription.trial_end) {
    const trialEnd = new Date(user.subscription.trial_end)
    if (new Date() > trialEnd) {
      return true
    }
  }
  return false
}

export const isTrialUser = (user: any) => {
  if (user?.subscription.name === SubscriptionPlansEnum.FREE && user?.subscription.trial_end) {
    const trialEnd = new Date(user.subscription.trial_end)
    if (new Date() < trialEnd) {
      return true
    }
  }
  return false
}
