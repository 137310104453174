import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import {useGetCompanyMutation} from '../../../services/companyServiceApi'
import SurveyModal from '../modal-page/SurveyModal'
import {UniversalNotification} from '../universal-components/universal-notification/UniversalNotification'
import GraphicsArea from './GraphicsArea'
import classes from './HomePage.module.scss'
import QuickAccessArea from './QuickAccessArea'
import RecentActivityArea from './RecentActivityArea'
import {Button} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import {isKlevereBetaUser} from '../../../utils/userRoles'

const HomePage: FC = () => {
  const {currentUser, isFreemium, setIsFreemium, toggleSidebar} = useAuth()
  const [getCompany] = useGetCompanyMutation()
  const [company, setCompany] = useState<any>()
  const [notification, setNotification] = useState('')
  const takeSurvey = useLocation().search === '?takeSurvey=true'

  const getCompanyData = async () => {
    try {
      const companyData = await getCompany({
        company_name: currentUser?.company_name,
        user_id: currentUser?.id,
      }).unwrap()
      setCompany(companyData)

      await checkTrial(companyData.subscription)
    } catch (err) {
      return {err}
    }
  }

  const checkTrial = async (companySubscription: any) => {
    const timeleft = new Date(companySubscription.trial_end)

    if (companySubscription.name !== 'Free Plan') return

    if (new Date() > new Date(companySubscription.trial_end)) {
      setNotification('You are currently in the freemium plan')
      return
    }

    setNotification(
      `You are currently on a free trial that ends ${timeleft.getFullYear()}-${
        timeleft.getMonth() + 1
      }-${timeleft.getDate()}`
    )
  }

  useEffect(() => {
    getCompanyData()
    var cookie: any = document.cookie.match('(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)')
    if (Array.isArray(cookie)) {
      try {
        var trakdeskCid = JSON.parse(cookie.pop())
        var cid = trakdeskCid['cid']
        document.querySelectorAll('a[href^="https://buy.stripe.com/"]').forEach(function (a: any) {
          var url = new URL(a.href)
          url.searchParams.set('client_reference_id', cid)
          a.href = url.href
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, [])

  return (
    <div className={classes.homepage}>
      {isKlevereBetaUser(currentUser?.email) ? null : (
        <>
          {notification.trim() !== '' ? (
            <UniversalNotification notificatonMessage={notification} upgrade={true} />
          ) : null}
          <div className='d-flex justify-content-between align-items-center w-100'>
            <h3 className={classes.homepage__title}>Welcome back, {currentUser?.first_name}!</h3>
            <Button variant='dark' className={'navbar-toggler-btn'} onClick={() => toggleSidebar()}>
              <GiHamburgerMenu />
            </Button>
          </div>
          <div className={`w-100 ${notification !== '' && 'mt-5'} m-md-0`}>
            <GraphicsArea currentUser={currentUser} company={company} />
          </div>
          {(!currentUser?.is_survey_completed || takeSurvey) && <SurveyModal />}
          <QuickAccessArea />
          <RecentActivityArea />
        </>
      )}
    </div>
  )
}

export default HomePage
