import OneLineIcon from '../../components/icons/workflow/OneLineIcon'
import ThreeLinesIcon from '../../components/icons/workflow/ThreeLinesIcon'
import toneData from '../universal-data/toneData'

const salesTemplatesData = {
  workflows: [],

  templates: [
    {
      id: 9,
      header: 'Sales',
      title: 'Subject Lines',
      description: 'Improve your email open rates with captivating subject lines.',
      url: 'subject-lines',
      axiosURL: 'generateSubjectLines',
      axiosHeader: 'subjectLines',
      Img: OneLineIcon,
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 130,
      },
      requiredQuestions: [
        {
          id: 11,
          title: 'What does your company do?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'product',
          tooltipAnchor: 'product_mark',
          tooltipText:
            'What is the nature of your business? What products or services do you offer?',
        },

        {
          id: 12,
          title: 'What are your unique selling points?',
          type: 'input',
          axiosParamName: 'points',
          tooltipAnchor: 'product_mark',
          tooltipText:
            'What is the nature of your business? What products or services do you offer?',
        },
      ],
      additionalQuestions: [
        {
          id: 13,
          title: 'Who is your target audience?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'audience',
          tooltipAnchor: 'audience_mark',
          tooltipText:
            'Who are you trying to reach? What are their demographics, interests, and pain points?',
        },
      ],
      optionalSelect: [
        {
          id: 14,
          title: 'Tone',
          options: [...toneData],
          axiosParamName: 'tone',
        },
      ],
    },
    {
      id: 18,
      header: 'Sales',
      title: 'Sales Proposals',
      description: 'Increase probability of deal closures with powerful sales proposals.',
      url: 'sales-proposals',
      axiosURL: 'generateSalesProposal',
      axiosHeader: 'salesProposal',
      Img: OneLineIcon,
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 530,
      },
      requiredQuestions: [
        {
          id: 21,
          title: "What are the customers' pain points/objectives?",
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'points',
          tooltipAnchor: 'points_mark',
          tooltipText:
            'What makes your product or service stand out in the market? What benefits or features differentiate you from competitors?',
        },

        {
          id: 22,
          title: 'What are your unique selling points? ',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'goal',
          tooltipAnchor: 'goal_mark',
          tooltipText: `To create a compelling proposal, it's vital to know what challenges the customer is facing and what goals they aim to achieve. What specific pain points can your product or service address?`,
        },
      ],
      additionalQuestions: [
        {
          id: 23,
          title: 'Who are the key decision makers?',
          type: 'input',
          placeholder: 'Enter...',
          axiosParamName: 'maker',
          tooltipAnchor: 'decision_mark',
          tooltipText: `Who are the key decision-makers, influencers, and stakeholders within the customer's organization?`,
        },
      ],
      optionalSelect: [],
    },

    {
      id: 3,
      header: 'Sales',
      title: 'Generate Cold Email',
      description: 'Personalize cold emails based on the lead and company',
      Img: ThreeLinesIcon,
      url: 'cold-emails',
      axiosURL: 'generateColdEmail',
      axiosHeader: 'email',
      relevanceRequest: false,
      easyRequest: false,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 97,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 31,
          title: 'Company name',
          type: 'input',
          placeholder: 'Receiver company..',
          axiosParamName: 'company',
          tooltipText: 'Enter name of prospect company',
          tooltipAnchor: 'receiver-company-name_mark',
          formColumn: 6,
        },

        {
          id: 33,
          title: 'Lead name',
          type: 'input',
          placeholder: 'Send to..',
          axiosParamName: 'send_to',
          tooltipText: 'Enter name of lead',
          tooltipAnchor: 'receiver-company-lead_mark',
          formColumn: 6,
        },
        {
          id: 32,
          title: 'Company summary',
          type: 'input',
          placeholder: 'Company summary..',
          axiosParamName: 'company_summary',
          tooltipText: 'Enter prospect company summary from company LinkedIn page',
          tooltipAnchor: 'receiver-company-summary_mark',
        },
        {
          id: 35,
          title: 'Context about your company & product/service',
          type: 'input',
          placeholder: 'Your company info..',
          axiosParamName: 'product_background',
        },
        {
          id: 34,
          title: 'Your company',
          type: 'input',
          placeholder: 'Your company..',
          axiosParamName: 'your_company',
          tooltipText: 'Enter your company name',
          tooltipAnchor: 'your-company-name_mark',
          formColumn: 12,
        },
      ],
      additionalQuestions: [
        {
          id: 36,
          title: "Receiver's info",
          type: 'input',
          placeholder: "Receiver's info..",
          axiosParamName: 'person_context',
        },
      ],
      optionalSelect: [],
    },
    {
      id: 5,
      header: 'Sales',
      title: 'Ice Breaker for Cold Outreach',
      description: 'Generate a personalized ice breaker for both LinkedIn & email outreach',
      Img: ThreeLinesIcon,
      url: 'ice-breaker',
      axiosURL: 'generateIceBreaker',
      axiosHeader: 'iceBreaker',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 60,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 127,
          title: 'LinkedIn profile URL',
          type: 'input',
          placeholder: 'Link to profile..',
          axiosParamName: 'linkedin_url',
          tooltipAnchor: 'linkedin_mark',
          tooltipText: 'LinkedIn profile of your prospect like you did with 3 key benefits',
        },

        // {
        //   id: 128,
        //   title: 'First Name',
        //   type: 'input',
        //   placeholder: 'Enter First Name..',
        //   axiosParamName: 'first_name',
        //   tooltipAnchor: 'name_mark',
        //   tooltipText: 'The name of your prospect.',
        // },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 2,
      header: 'Sales',
      title: 'Personalize LinkedIn Requests',
      description: 'Personalize connection requests based on prospect posts and profile.',
      Img: ThreeLinesIcon,
      url: 'linkedin-requests',
      axiosURL: 'generateLinkedinRequest',
      axiosHeader: 'request',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 60,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 51,
          title: 'LinkedIn URL',
          type: 'input',
          placeholder: 'Link ..',
          axiosParamName: 'linkedin_url',
          tooltipAnchor: 'linkedin_mark',
          tooltipText: 'LinkedIn URL of your prospect',
        },

        // {
        //   id: 52,
        //   title: 'Context about your product and service ',
        //   type: 'input',
        //   placeholder: 'Enter context..',
        //   axiosParamName: 'product_context',
        //   tooltipAnchor: 'context_mark',
        //   tooltipText: 'More context equals better tailored connection requests.',
        // },

        // {
        //   id: 52,
        //   title: 'First Name',
        //   type: 'input',
        //   placeholder: 'Enter First Name..',
        //   axiosParamName: 'first_name',
        //   tooltipAnchor: 'context_mark',
        //   tooltipText: 'First name of your prospect',
        // },

        // {
        //   id: 54,
        //   title: 'My Company',
        //   type: 'input',
        //   placeholder: 'Enter Company Name..',
        //   axiosParamName: 'my_company',
        //   tooltipAnchor: 'company_mark',
        //   tooltipText: 'Name of your company',
        // },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 10,
      header: 'Sales',
      title: '3 Key Benefits',
      description: 'Craft a 3-point personalized benefit section in your outreach.',
      Img: ThreeLinesIcon,
      url: 'linkedin-key-benefits',
      axiosURL: 'generateThreeKeyBenefits',
      axiosHeader: 'threeKeyPoints',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 97,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 61,
          title: 'LinkedIn profile URL',
          type: 'input',
          placeholder: 'Link to profile..',
          axiosParamName: 'linkedin_url',
          tooltipAnchor: 'linkedin_mark',
          tooltipText: 'LinkedIn URL of your prospect',
        },

        {
          id: 62,
          title: 'First Name',
          type: 'input',
          placeholder: 'Enter First Name..',
          axiosParamName: 'first_name',
          tooltipAnchor: 'name_mark',
          tooltipText: 'The name of your prospect',
        },

        {
          id: 63,
          title: 'Your Company',
          type: 'input',
          placeholder: 'Enter your Company name..',
          axiosParamName: 'my_company',
          tooltipAnchor: 'company_mark',
          tooltipText: 'Enter your company name',
        },

        {
          id: 64,
          title: 'Context about your product and service',
          type: 'input',
          placeholder: 'Enter context...',
          axiosParamName: 'product_context',
          tooltipAnchor: 'description_mark',
          tooltipText: 'More context equals better tailored personalised sections.',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 6,
      header: 'Sales',
      title: 'Company Research with Website',
      description: 'Enrich your outreach with prospect company research via website.',
      Img: ThreeLinesIcon,
      url: 'company-research',
      axiosURL: 'generateCompanyResearchWithWebsite',
      axiosHeader: 'websiteResearch',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 161,
        credits: 9,
      },
      requiredQuestions: [
        {
          id: 71,
          title: 'Company website URL [Example: https://example.com] ',
          type: 'input',
          placeholder: 'Link to website..',
          axiosParamName: 'company_url',
          tooltipAnchor: 'url_mark',
          tooltipText: 'Paste website URL',
        },

        {
          id: 72,
          title: 'Context about your product and service ',
          type: 'input',
          placeholder: 'Enter context..',
          axiosParamName: 'product_context',
          tooltipAnchor: 'url_mark',
          tooltipText: 'Enter details about your product or service',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 7,
      header: 'Sales',
      title: 'Company Research with LinkedIn',
      description: 'Enrich your outreach with prospect company research via LinkedIn.',
      Img: ThreeLinesIcon,
      url: 'company-research-linkedin',
      axiosURL: 'generateCompanyLinkedinResearch',
      axiosHeader: 'research',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 200,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 81,
          title: 'Company LinkedIn URL ',
          type: 'input',
          placeholder: 'Link to company profile..',
          axiosParamName: 'company_linkedin_url',
          tooltipAnchor: 'url_mark',
          tooltipText: 'Paste Linkedin URL',
        },
        {
          id: 82,
          title: 'Context about your product and service ',
          type: 'input',
          placeholder: 'Enter context..',
          axiosParamName: 'product_context',
          tooltipAnchor: 'url_mark',
          tooltipText: 'Enter details about your product or service',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 8,
      header: 'Sales',
      title: 'Industry Research',
      description: 'Enrich your data with the top 5 industry trends via Google.',
      Img: ThreeLinesIcon,
      url: 'industry-research',
      axiosURL: 'generateIndustryResearch',
      axiosHeader: 'research',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 122,
        credits: 6,
      },
      requiredQuestions: [
        {
          id: 91,
          title: 'Industry name',
          type: 'input',
          placeholder: 'Industry to research..',
          axiosParamName: 'industry',
          tooltipAnchor: 'url_mark',
          tooltipText: 'Enter industry name',
        },

        {
          id: 92,
          title: 'Context about your product and service ',
          type: 'input',
          placeholder: 'Enter context..',
          axiosParamName: 'product_context',
          tooltipAnchor: 'url_mark',
          tooltipText: 'Enter details about your product or service',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 1,
      header: 'Sales',
      title: 'Lead Finder (Beta)',
      description: 'Research companies to discover relevant leads.',
      Img: ThreeLinesIcon,
      url: 'company-leads-custom',
      axiosURL: 'generateLeadFinder',
      axiosHeader: 'lead',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 0,
        credits: 13,
      },
      requiredQuestions: [
        {
          id: 100,
          title: 'Company Name',
          type: 'input',
          placeholder: 'Name of compant to research..',
          axiosParamName: 'company',
          formColumn: 6,
        },
        {
          id: 101,
          title: 'Company Website',
          type: 'input',
          placeholder: 'Company to research..',
          axiosParamName: 'company_website',
          tooltipAnchor: 'url_mark',
          formColumn: 6,
          tooltipText: 'Paste website URL',
        },
        {
          id: 102,
          title: 'Role to find',
          type: 'input',
          placeholder: 'Enter role..',
          axiosParamName: 'role',
          tooltipAnchor: 'url_mark',
          tooltipText: 'Enter job title',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 1,
      header: 'Sales',
      title: 'Professional Email Finder (Beta)',
      description: "Enter your lead's LinkedIn profile to find verified business email ID's",
      Img: ThreeLinesIcon,
      url: 'professional-email-finder',
      axiosURL: 'professionalEmailFinder',
      axiosHeader: 'lead',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 0,
        credits: 13,
      },
      requiredQuestions: [
        {
          id: 100,
          title: 'Linkedin profile url',
          type: 'input',
          placeholder: 'Linkedin profile url',
          axiosParamName: 'url',
          formColumn: 6,
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 4,
      header: 'Sales',
      title: 'Pain-Point Cold Email',
      description:
        'Personalize cold emails based on pain points and solutions. Ice breaker included',
      Img: ThreeLinesIcon,
      url: 'pain-point-email',
      axiosURL: 'generatePainpointEmail',
      axiosHeader: 'email',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 300,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 121,
          title: 'First Name',
          type: 'input',
          placeholder: 'Enter First Name',
          axiosParamName: 'first_name',
          tooltipAnchor: 'name_mark',
          tooltipText: 'The name of the email recipient',
          formColumn: 6,
        },
        {
          id: 122,
          title: 'Company Name',
          type: 'input',
          placeholder: "Enter Recipient's Company Name",
          axiosParamName: 'company_name',
          tooltipAnchor: 'context_mark',
          tooltipText: "Recipient's Company Name",
          formColumn: 6,
        },
        {
          id: 123,
          title: 'Pain Points',
          type: 'input',
          placeholder: 'Enter Paint Points',
          axiosParamName: 'pain_point',
          tooltipAnchor: 'context_mark',
          tooltipText: 'Pain points of recipient',
        },
        {
          id: 125,
          title: 'Solution to Pain Points',
          type: 'input',
          placeholder: 'Enter Solution',
          axiosParamName: 'solution',
          tooltipAnchor: 'context_mark',
          tooltipText: 'Your companies solution to the pain points',
        },

        {
          id: 126,
          title: "Recipient's LinkedIn URL",
          type: 'input',
          placeholder: 'Enter URL',
          axiosParamName: 'linkedin_url',
          tooltipAnchor: 'context_mark',
          tooltipText: 'LinkedIn URL of recipient',
          formColumn: 6,
        },
        {
          id: 124,
          title: 'Your Company',
          type: 'input',
          placeholder: 'Enter Company Name',
          axiosParamName: 'our_company_name',
          tooltipAnchor: 'context_mark',
          tooltipText: 'Your companies name',
          formColumn: 6,
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },

    {
      id: 100,
      header: 'Sales',
      title: 'Resource Finder',
      description: `Our AI will analyze your lead's LinkedIn profile and suggest resources as a conversation starter.`,
      Img: ThreeLinesIcon,
      url: 'resource-finder',
      axiosURL: 'generateResourceFinder',
      axiosHeader: 'resourceFinder',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 60,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 127,
          title: 'LinkedIn profile URL',
          type: 'input',
          placeholder: 'Link to profile..',
          axiosParamName: 'linkedin_url',
          tooltipAnchor: 'linkedin_mark',
          tooltipText: 'Paste Linkedin URL',
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    // {
    //   id: 10,
    //   header: 'Sales',
    //   title: 'Lead Finder (Beta)',
    //   description: 'Research companies to discover relevant leads.',
    //   Img: ThreeLinesIcon,
    //   url: 'company-leads-custom',
    //   axiosURL: 'generateLeadFinder',
    //   axiosHeader: 'lead',
    //   relevanceRequest: false,
    //   easyRequest: true,
    //   imgGenerate: false,
    //   formType: 'regular',
    //   limits: {
    //     words: 0,
    //     credits: 13,
    //   },
    //   requiredQuestions: [
    //     {
    //       id: 101,
    //       title: 'Company Website',
    //       type: 'input',
    //       placeholder: 'Company to research..',
    //       axiosParamName: 'company_website',
    //       tooltipAnchor: 'url_mark',
    //       tooltipText:
    //         'Paste website URL',
    //     },
    //     {
    //       id: 102,
    //       title: 'Role to find',
    //       type: 'input',
    //       placeholder: 'Enter role..',
    //       axiosParamName: 'role',
    //       tooltipAnchor: 'url_mark',
    //       tooltipText:
    //         'Enter job title',
    //     },
    //   ],
    //   additionalQuestions: [],
    //   optionalSelect: [],
    // },
  ],
}

export default salesTemplatesData
