import {useEffect, useState, type FC} from 'react'
import {Button, Dropdown, Form, InputGroup, Modal, Spinner, Table} from 'react-bootstrap'
import {BsBoxSeam, BsPeople, BsThreeDotsVertical} from 'react-icons/bs'
import classes from '../../profile-page/MembersArea.module.scss'
import {Tables} from '../../../../app/modules/widgets/components/Tables'

const ProjectDetailsModal = ({
  show,
  setShow,
  project,
}: {
  show: boolean
  setShow: (show: boolean) => void
  project: any
}) => {
  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          style={{
            border: 'none',
          }}
        >
          <Modal.Title>
            {project && project.name
              ? project.name.length > 30
                ? `${project.name.slice(0, 30)}...`
                : project.name
              : 'No Project Name'}{' '}
            Details
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            {/* <h5>{project && project.name}</h5>
<h5>{project && project.workflow}</h5> */}

            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{width: '10%'}}>#</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {project && project.values && project.values.length > 0 ? (
                  project.values.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2} style={{textAlign: 'center'}}>
                      No values found for this project!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: '1rem',
              borderTop: 'none',
            }}
          >
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='button'
                className={classes.maping__modalCloseButton}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ProjectDetailsModal
