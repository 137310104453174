import {useState, useRef, type FC, useEffect} from 'react'
import ImportIcon from '../../icons/workflow/ImportIcon'
import {useParams} from 'react-router-dom'
import UniversalHiddenScreen from '../hidden-screen/UniversalHiddenScreen'
import MapingModal from './MapingModal'
import classes from './ImportCSVBtn.module.scss'
import templatesData from '../../../data/finance/financeTemplatesData'
import ConfirmationModal from '../universal-modal/ConfirmationModal'
import {bool} from 'aws-sdk/clients/signer'
import {integer} from 'aws-sdk/clients/cloudfront'
import {toast} from 'react-toastify'

interface ImportCSVBtnProps {
  currentWorkflowRowsLimit: number
  myRef: React.RefObject<HTMLDivElement>
  setRowsCount: Function
  setColumnsOrder: Function
}

const ImportCSVBtn: FC<ImportCSVBtnProps> = ({
  currentWorkflowRowsLimit,
  myRef,
  setRowsCount = () => {},
  setColumnsOrder = () => {},
}) => {
  const [visibleBackground, setVisibleBackground] = useState<boolean>(false)
  const [CSVTableHeaders, setCSVTableHeaders] = useState<string[]>([])
  const [CSVTableRows, setCSVTableRows] = useState<string[]>([])
  const [bulkTableHeaders, setBulkTableHeaders] = useState<string[]>([])
  const [triggerFormSubmited, setTriggerFormSubmited] = useState<number | boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [userConfirmation, setUserConfirmation] = useState<boolean>(false)
  const [userConfirmationVisibility, setUserConfirmationVisibility] = useState<boolean>(false)
  const [userConfirmationMessage, setUserConfirmationMessage] = useState<string>('')
  const [userConfirmationMessageType, setUserConfirmationMessageType] = useState<string>('')

  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const resetImportCSVInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const showConfirmationDialog = (totalRows: any, currentWorkflowRowsLimit: any) => {
    if (currentWorkflowRowsLimit <= 0) {
      setUserConfirmationMessageType('ERROR')
      setUserConfirmationMessage(`Sorry, you don't have any credits left for this workflow!`)
    } else {
      setUserConfirmationMessageType('WARNING')
      setUserConfirmationMessage(`Your CSV has ${totalRows} rows which exceeds the limit.
      Only ${currentWorkflowRowsLimit} can be imported. Do you want to proceed or upgrade your limit?`)
    }

    handleUserConfirmationVisibility()
  }

  const handleUserConfirmationVisibility = () => {
    setUserConfirmationVisibility((prev) => !prev)
  }

  const handleUserConfirmation = (confirmation: string) => {
    handleUserConfirmationVisibility()
    if (confirmation === 'SUBMIT') {
      setUserConfirmation(true)
    } else {
      setUserConfirmation(false)
      handleVisibility()
    }
  }

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.files !== null &&
      e.target.files.length > 0 &&
      e.target.files[0].type === 'text/csv'
    ) {
      /* find headers from table in app*/
      const theadRow = myRef?.current?.querySelector('tr')?.querySelectorAll('th')
      const theadCells = () => {
        if (theadRow) {
          return [].slice.call(theadRow).map((cell: HTMLTableCellElement, index) => {
            return cell.textContent?.replace(/\s*\*/g, '')
          })
        }
        return []
      }

      const thead = theadCells() as []
      thead.shift()

      // B2B email finder, for now we set the headers manually
      if (window.location.href.includes('professional-email-finder')) {
        setBulkTableHeaders(['Linkedin profile url'])
      }else{
        setBulkTableHeaders(thead)
      }
      
      const file = e.target.files[0]

      /* reset the csv file input value */
      resetImportCSVInput()

      const reader = new FileReader()
      reader.onload = function (e) {
        const csvData: any = e?.target?.result

        /* ensures that the CSV rows remain within the specified maximum rows limitation */
        const csvRows = csvData.split('\n')
        const totalRows = csvRows.length

        if (totalRows > currentWorkflowRowsLimit) {
          showConfirmationDialog(totalRows, currentWorkflowRowsLimit)

          /* ensure we splice as per our exact limit */
          if (currentWorkflowRowsLimit > 0) {
            let actualWorkflowRowsLimit: number = parseInt(currentWorkflowRowsLimit.toString()) + 1
            csvRows.splice(actualWorkflowRowsLimit)
          }
        }

        /* We are exporting csv with double quotation but editor doesn't, so first rows is the headers. */
        const preparedData = csvRows.map((row: any) => {
          let columns = []
          let current = ''
          let withinQuotes = false

          for (let char of row) {
            if (char === ',' && !withinQuotes) {
              columns.push(current)
              current = ''
            } else if (char === '"') {
              withinQuotes = !withinQuotes
            } else {
              current += char
            }
          }

          columns.push(current)
          return columns.map((unit) => unit.replace(/"/g, ''))
        })

        const [headers, ...rows] = preparedData

        setCSVTableHeaders(headers)
        setCSVTableRows(rows)
        handleVisibility()
      }
      reader.readAsText(file)
    } else {
      toast.warning('Please upload a valid CSV file.')
    }
  }

  useEffect(() => {
    triggerFormSubmited && setRowsCount(CSVTableRows)
  }, [triggerFormSubmited])

  return (
    <div className={classes.import}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={
          userConfirmationVisibility ? (
            <ConfirmationModal
              type={userConfirmationMessageType}
              handleUserConfirmation={handleUserConfirmation}
              message={userConfirmationMessage}
            />
          ) : (
            <MapingModal
              CSVTableHeaders={CSVTableHeaders}
              bulkTableHeaders={bulkTableHeaders}
              setColumnsOrder={setColumnsOrder}
              setTriggerFormSubmited={setTriggerFormSubmited}
              handleVisibility={handleVisibility}
            />
          )
        }
      />
      <label className={classes.import__btn}>
        <ImportIcon /> Import CSV
        <input type='file' ref={fileInputRef} onChange={uploadFile} />
      </label>
    </div>
  )
}

export default ImportCSVBtn
