import type {FC} from 'react'
import templatesData from '../../../data/hr/hrTemplatesData'
import {useParams} from 'react-router-dom'
import BulkRun from '../../universal-components/bulk-run/BulkRun'

interface HRBulkRunPageProps {}

const HRBulkRunPage: FC<HRBulkRunPageProps> = () => {
  const {templateUrl} = useParams()

  const templateItem: any = templatesData.templates.find((item: any) =>
    item.url.includes(templateUrl)
  )
  
  const title = templateItem.title

  return <BulkRun title={title} templateItem={templateItem} />
}

export default HRBulkRunPage
