import {useEffect, useState} from 'react'
import {useCreateBrandVoiceMutation} from '../../../services/brandVoiceServiceApi'
import {toast} from 'react-toastify'
import {GlobalErrorMessagesEnum} from '../../../types/types'
import {Button, Form, InputGroup, Modal, Row, Spinner} from 'react-bootstrap'
import classes from '../profile-page/MembersArea.module.scss'
import {useAuth} from '../../../app/modules/auth'
import {useCreateProjectMutation} from '../../../services/ProjectServiceApi'
import hrTemplatesData from '../../data/hr/hrTemplatesData'
import salesTemplatesData from '../../data/sales/salesTemplatesData'
import marketingTemplatesData from '../../data/marketing/marketingTemplatesData'
import Select from 'react-select'
import * as formik from 'formik'
import * as yup from 'yup'

const ProjectModal = ({
  setShowTrainingModal,
  showTrainingModal,
  refetch,
  data,
}: {
  showTrainingModal: any
  setShowTrainingModal: any
  refetch: any
  data: any
}) => {
  const [submitProject, {isLoading}] = useCreateProjectMutation()
  const {currentUser} = useAuth()
  const [errors, setErrors] = useState([])
  const [workflowName, setWorkflowName] = useState('')
  const [name, setName] = useState('')
  const [handlePostType, setHandlePostType] = useState('NEW_POST')
  const [brandVoiceId, setBrandVoiceId] = useState(null)

  useEffect(() => {
    if (data) {
      setName(data.name)
      setWorkflowName('')
      setBrandVoiceId(data.id)
      setHandlePostType('OLD_POST')
    }
  }, [data])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const data = {
      name: name,
      workflow: workflowName,
    }

    submitProject(data)
      .unwrap()
      .then((data) => {
        refetch()
        toast.success(`Project "${data?.name && data.name.slice(0, 10)}" created successfully`)
        // setContent(data.personality_traits)
        // setHandlePostType('OLD_POST')
        handleClose()
      })
      .catch((error) => {
        if (error.status === 400) {
          setErrors(error.data.message)
          toast.error(error.data.message[0])
          return
        }

        if (error.status === 409) {
          setErrors(error.data.message)
          toast.error(error.data.message)
          return
        }
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
  }

  // const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setType(event.target.value)
  // }

  const handleInputChange = (event: any) => {
    event.target.value = event.target.value.replace(/\D+/g, '')
  }

  const handleClose = () => {
    setName('')
    setWorkflowName('')
    setShowTrainingModal(false)
    setBrandVoiceId(null)
  }

  const mergedTemplatesTitle = [
    ...hrTemplatesData.templates,
    ...salesTemplatesData.templates,
    ...marketingTemplatesData.templates,
  ].map((template) => ({
    value: template.title,
    label: template.title,
  }))

  const {Formik} = formik

  const schema = yup.object().shape({
    workflow: yup.string().required().min(3).max(100),
    name: yup.string().required().min(3).max(100),
  })

  return (
    <>
      <Modal show={showTrainingModal} onHide={handleClose} centered backdrop='static'>
        <Modal.Header
          style={{
            border: 'none',
          }}
        >
          <Modal.Title>{!data ? 'Add Project' : 'Edit Project'} </Modal.Title>
        </Modal.Header>

        {/* {
          errors && (
            <div className='alert alert-danger' role='alert'>
              <ul>
                {errors.map((error:any, index:any) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )
        } */}

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <InputGroup className='my-4 mt-2 d-flex justify-content-between align-items-center'>
              <label htmlFor='words'>
                Name of your Project <sup className='text-danger'>*</sup>
              </label>
              <input
                name='name'
                type='text'
                className='p-3 w-100'
                placeholder='Name of your Project'
                style={{
                  borderRadius: '5px',
                  border: '1px solid #ced4da',
                  outline: 'none',
                }}
                minLength={3}
                maxLength={100}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Form.Control.Feedback type='invalid'>
                Please choose a username.
              </Form.Control.Feedback>
            </InputGroup>

            <label htmlFor='words'>
              Select Workflow
              <sup className='text-danger'>*</sup>
            </label>

            <Select
              options={mergedTemplatesTitle}
              onChange={(e) => setWorkflowName(e ? e.value : '')}
              className='w-100'
            />
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: '1rem',
              borderTop: 'none',
            }}
          >
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='button'
                className={classes.maping__modalCloseButton}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
            <div className={classes.maping__modalSubmitOuter}>
              <button type='submit' className={classes.maping__modalSubmitButton}>
                {isLoading && (
                  <Spinner animation='border' role='status' size='sm'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                )}

                {!isLoading && 'Submit'}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ProjectModal
