import React, {useEffect, useRef, useState} from 'react'
import CrossIcon from '../icons/chat/CrossIcon'
import { useFirstLoginMutation } from '../../../services/authServiceApi';
import { useAuth } from '../../../app/modules/auth';

const keyMap = {isSpace: false, isK: false}


declare global {
  interface Window {
    Supademo: (event: string, options: { variables: Record<string, any> }) => void;
  }
}


export const DemoAppTour = () => {
  const {currentUser} = useAuth()
  const [firstLogin] = useFirstLoginMutation()

  useEffect(() => {
    firstLogin(currentUser?.id).unwrap()
  }, []);
   

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: 'calc(50% + 4px)',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex:999999
      }}
    >
      <iframe
        id='targetFrame'
        title='app_demo_tour'
        src='https://app.supademo.com/embed/clujz9ffy0jr712mev2izmreo'
        allow='clipboard-write'
        allowFullScreen
        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
      ></iframe>

      <div
        style={{
          position: 'absolute',
          background: '#282828',
          borderRadius: '2rem',
          padding: '8px 12px',
          top: '46px',
          right: '40px',

          // left: '50%',
          // width: '100%',
        }}
      >
        <p
          style={{
            fontSize: '1.1rem',
            color: '#fff',
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
        Cancel Demo
          <div
            style={{cursor: 'pointer'}}
            onClick={() => {
              window.location.reload()
            }}
          >
            X
          </div>
        </p>
      </div>
    </div>
  )
}

export default DemoAppTour
