import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import * as authHelper from '../app/modules/auth/core/AuthHelpers'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const projectServiceApi = createApi({
  reducerPath: 'projectServiceApi',
  baseQuery: refreshBaseQuery,
  tagTypes: ['Projects', 'Project'],
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: ({keywords, page}) => ({
        url: `${API_URL}/project?keywords=${keywords}&page=${page}`,
      }),
      providesTags: ['Projects'],
    }),

    createProject: builder.mutation({
      query: (activity) => ({
        url: `${API_URL}/project/`,
        method: 'POST',
        body: activity,
      }),
      invalidatesTags: ['Projects'],
    }),
    getProject: builder.query({
      query: (uuid) => ({
        url: `${API_URL}/project/${uuid}`,
      }),
      providesTags: ['Project'],
    }),
    deleteProject: builder.mutation({
      query: ({id}) => ({
        url: `${API_URL}/project/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Project'],
    }),
  }),
})

export const {
  useCreateProjectMutation,
  useGetProjectsQuery,
  useLazyGetProjectQuery,
  useDeleteProjectMutation,
} = projectServiceApi
