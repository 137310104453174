import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const CrossIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM14.9032 12.625C15.0927 12.8145 15.0927 13.121 14.9032 13.3105L13.3065 14.9032C13.1169 15.0927 12.8105 15.0927 12.621 14.9032L10 12.2581L7.375 14.9032C7.18548 15.0927 6.87903 15.0927 6.68952 14.9032L5.09677 13.3065C4.90726 13.1169 4.90726 12.8105 5.09677 12.621L7.74194 10L5.09677 7.375C4.90726 7.18548 4.90726 6.87903 5.09677 6.68952L6.69355 5.09274C6.88306 4.90323 7.18952 4.90323 7.37903 5.09274L10 7.74194L12.625 5.09677C12.8145 4.90726 13.121 4.90726 13.3105 5.09677L14.9073 6.69355C15.0968 6.88306 15.0968 7.18952 14.9073 7.37903L12.2581 10L14.9032 12.625Z'
        fill={color}
      />
    </svg>
  )
}

export default CrossIcon
