import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {refresh} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {useLazyGetUserByTokenQuery, useLazyRefreshQuery} from '../../../../services/authServiceApi'
import {toast} from 'react-toastify'
import {SubscriptionPlansEnum} from '../../../../types/types'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser?: UserModel
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  isFreemium: boolean
  setIsFreemium: Dispatch<SetStateAction<boolean>>
  sidebarOpen: boolean
  toggleSidebar: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  isFreemium: false,
  setIsFreemium: () => {},
  sidebarOpen: false,
  toggleSidebar: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isFreemium, setIsFreemium] = useState<boolean>(false)

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)

    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }
  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }
  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        isFreemium,
        setIsFreemium,
        sidebarOpen,
        toggleSidebar,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, saveAuth, setIsFreemium} = useAuth()
  const didRequest = useRef(false)
  const [getUserByToken] = useLazyGetUserByTokenQuery()

  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiTokens: AuthModel) => {
      try {
        if (!didRequest.current) {
          const data = await getUserByToken(apiTokens.access_token).unwrap()
          if (data) {
            if (
              data.subscription.name === SubscriptionPlansEnum.FREE &&
              new Date(data.subscription.trial_end) < new Date()
            ) {
              setIsFreemium(true)
            }
            setCurrentUser(data)
          }
        }
      } catch (error: any) {
        toast.error(error?.data?.message || 'Can not retrieve user info')
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth) {
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }

    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}