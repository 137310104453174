import {checkLimits, updateUsage} from '../../app/modules/auth/core/_requests'
import axios from 'axios'
import {useAuth} from '../../app/modules/auth'
import removeMarkdown from '../../utils/removeMarkdown'

interface IUseRelevanceRequestProps {
  setCurrentAnswer: Function
  requiredQuestions: [] | any
  additionalQuestions: [] | any
  axiosURL: string
  easyRequest: boolean
  inputs: any
  optionalSelect?: [] | any
  myRef: React.RefObject<HTMLFormElement>
  setIsLoading?: Function
  setError: Function
  handleSaveActivity?: Function
}

interface IUseRelevanceRequestResult {
  handleForm: (e: React.FormEvent | any) => void
}

const useRelevanceRequest = ({
  myRef,
  setCurrentAnswer,
  requiredQuestions,
  additionalQuestions,
  axiosURL,
  easyRequest,
  inputs,
  optionalSelect,
  setError,
  setIsLoading = () => {},
  handleSaveActivity = () => {},
}: IUseRelevanceRequestProps): IUseRelevanceRequestResult => {


  console.log("xinputs",inputs)


  const RELEVANCE_SECRET = process.env.REACT_APP_RELEVANCE_SECRET
  const {auth} = useAuth()

  const easyRequestCase = (res: any) => {
    updateUsage({words: res.data.output.count_words, credits: res.data.cost})
    const answer =
      res.data.output?.answer === null || res.data.status === 'failed'
        ? 'Oops!.. Incorrect data was passed!.. Try again!'
        : res.data.output.answer

    const cleanAnswer = removeMarkdown(answer)
    setIsLoading(false)
    handleSaveActivity(answer)
    return setCurrentAnswer(cleanAnswer)
  }

  const hardRequestCase = (response: any) => {
    const pollInterval = setInterval(() => {
      axios
        .get(
          `https://api-d7b62b.stack.tryrelevance.com/latest/studios/${response.data.studio_id}/async_poll/${response.data.job_id}?ending_update_only=true&project=${response.data.project}`,
          {headers: {Authorization: `:${RELEVANCE_SECRET}`}}
        )
        .then((res) => {
          if (res.data.type === 'complete') {
            const answer = res.data.updates[0].output.output.answer
            const cleanAnswer = removeMarkdown(answer)
            setCurrentAnswer(cleanAnswer)
            setIsLoading(false)
            updateUsage({
              words: res.data.updates[0].output.output.count_words,
              credits: res.data.updates[0].output.cost,
            })
            handleSaveActivity(answer)
            clearInterval(pollInterval)
          }
          if (res.data.type === 'failed') {
            clearInterval(pollInterval)
            setCurrentAnswer(`The unknown error was occuried, please try again!`)
            setIsLoading(false)
          }
        })
        .catch((err) => {
          handleSaveActivity('An error occured')
          setCurrentAnswer(`The unknown error was occuried, please try again!`)
          clearInterval(pollInterval)
          setIsLoading(false)
        })
    }, 5000)
  }

  const handleForm = async (e: React.FormEvent | any) => {
    e?.type === 'submit' && e.preventDefault()
    const form = myRef.current
    if (form && form.checkValidity()) {
      setIsLoading(true)
      checkLimits(auth?.access_token)
        .then(async () => {
          axios
            .post(
              `https://api-d7b62b.stack.tryrelevance.com/latest/studios/${axiosURL}/trigger_limited${
                easyRequest ? '' : '_async'
              }`,
              {
                params: {
                  [requiredQuestions[0].axiosParamName]: inputs.requiredQuestion1,
                  [requiredQuestions[1]?.axiosParamName]: inputs.requiredQuestion2,
                  [requiredQuestions[2]?.axiosParamName]: inputs.requiredQuestion3,
                  [requiredQuestions[3]?.axiosParamName]: inputs.requiredQuestion4,
                  [requiredQuestions[4]?.axiosParamName]: inputs.requiredQuestion5,

                  [additionalQuestions[0]?.axiosParamName]: inputs.additionalQuestion1,
                  [additionalQuestions[1]?.axiosParamName]: inputs.additionalQuestion2,
                  [additionalQuestions[2]?.axiosParamName]: inputs.additionalQuestion3,

                  [optionalSelect[0]?.axiosParamName]: inputs.optionalSelect1,
                  [optionalSelect[1]?.axiosParamName]: inputs.optionalSelect2,
                  [optionalSelect[2]?.axiosParamName]: inputs.optionalSelect3,
                  [optionalSelect[3]?.axiosParamName]: inputs.optionalSelect4,
                  [optionalSelect[4]?.axiosParamName]: inputs.optionalSelect5,
                  [optionalSelect[5]?.axiosParamName]: inputs.optionalSelect6,

                  keywords: inputs.additionalQuestion1.split(' '),
                  language: inputs.language,
                  // linkedin_url: inputs.linkedin_url,
                },

                project: '77b8772bd1a0-4c2f-849c-c4ef9c01c40b',
              },
              {
                headers: {
                  Authorization: '',
                },
              }
            )
            .then((response) => {
              easyRequest ? easyRequestCase(response) : hardRequestCase(response)
            })
            .catch((err) => {
              setCurrentAnswer(
                `Oops!.. ${
                  err.response.data.message ? err.response.data.message : 'An error occured'
                }`
              )
              handleSaveActivity(err.response.data.message || 'An error occured')
              // console.error('An error occured ', err)
            })
        })
        .catch((err) => {
          setCurrentAnswer(
            `Oops!.. ${err.response.data.message ? err.response.data.message : 'An error occured'}`
          )
          if (err.response.status) {
            setError(err.response.data.message || 'Some troubles happened to Internet connection')
            handleSaveActivity(
              err.response.data.message || ' Some troubles happened to Internet connection'
            )
            setCurrentAnswer(
              `Oops!.. ${
                err.response.data.message ? err.response.data.message : 'An error occured'
              }`
            )
          }
          setIsLoading(false)
        })

      // setResults(['Some results of request will be here...'])
    }
  }

  return {handleForm}
}

export default useRelevanceRequest
